import React, { createRef, lazy, useEffect } from "react";
import { Redirect, Route } from "react-router";
import { BrowserRouter } from "react-router-dom";
import { SnackbarProvider } from "notistack";

// Dynamic links
import { AppUrlListener } from "./AppUrlListener";

// Settings
import versionObj from "./version.json";

// Styles
import "./scss/App.scss";

// Custom components
import Loadable from "./components/general/Loadable";
import Loading from "./components/general/Loading";

// Utils
import settings from "./settings.json";
import { Helmet } from "react-helmet";

// Hooks
import { useFetchUser } from "./hooks/useFetchUser";

import useVersion from "./hooks/useVersion";

// Ionic
import { IonApp, IonRouterOutlet, IonSplitPane, setupIonicReact, isPlatform } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";
import "@ionic/react/css/normalize.css";
/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

// Pages
const Onboarding = Loadable(lazy(() => import("./components/pages/Onboarding")));
const Home = Loadable(lazy(() => import("./components/pages/Home")));
const Event = Loadable(lazy(() => import("./components/pages/Event")));
const Events = Loadable(lazy(() => import("./components/pages/Events")));
const MainLayout = Loadable(lazy(() => import("./components/layouts/MainLayout")));
const Profile = Loadable(lazy(() => import("./components/pages/Profile")));
const Carbon = Loadable(lazy(() => import("./components/pages/CarbonFootprint")));

setupIonicReact();

const PublicRoute = ({ title, path, exact = true, Component, Layout }: any) => {
  if (Layout) {
    return (
      <Route exact={exact} path={path}>
        <Layout>
          <Helmet title={`${title} - ${settings.app.title}`} />
          <Component />
        </Layout>
      </Route>
    );
  }

  return (
    <Route exact={exact} path={path}>
      <Helmet title={`${title} - ${settings.app.title}`} />
      <Component />
    </Route>
  );
};

const PrivateRoute = ({ title, path, exact = true, Component, Layout }: any) => {
  const { currentUser } = useFetchUser();

  if (settings.isMobile) {
    //Hack-ish code to allow MAGIC LINKS to work
    if (
      (!currentUser && window.location.href.includes("?apiKey=")) ||
      window.location.href.includes("?referrerCode=")
    ) {
      return (
        <Route exact={exact} path={path}>
          <Component />
        </Route>
      );
    }

    return currentUser ? (
      <Route exact={exact} path={path}>
        <Component />
      </Route>
    ) : (
      <Redirect to="/" />
    );
  } else {
    if (
      (!currentUser && window.location.href.includes("?apiKey=")) ||
      window.location.href.includes("?referrerCode=")
    ) {
      return (
        <Route exact={exact} path={path}>
          <Layout>
            <Helmet title={`${title} - ${settings.app.title}`} />
            <Component />
          </Layout>
        </Route>
      );
    }

    return currentUser ? (
      <Route exact={exact} path={path}>
        <Layout>
          <Helmet title={`${title} - ${settings.app.title}`} />
          <Component />
        </Layout>
      </Route>
    ) : (
      <Redirect to="/" />
    );
  }
};

const Routing = () => {
  if (settings.isMobile) {
    return (
      <>
        <PublicRoute path="/" Component={Home} title="Home" />
        <PublicRoute path="/events" Component={Events} title="Events" />
        <PublicRoute path="/event/:id" Component={Event} title="Event" />
        <PrivateRoute path="/profile" Component={Profile} title="Profile" />
        <PublicRoute path="/onboarding" Component={Onboarding} />
        {/* <Redirect to="/" /> */}
      </>
    );
  } else
    return (
      <>
        <PublicRoute path="/" Component={Home} Layout={MainLayout} title="Home" />
        <PublicRoute path="/events" Component={Events} Layout={MainLayout} title="Events" />
        <PublicRoute path="/event/:id" Component={Event} Layout={MainLayout} title="Event" />
        <PrivateRoute path="/profile" Component={Profile} Layout={MainLayout} title="Profile" />
        <PublicRoute path="/onboarding" Component={Onboarding} />
        {/* <Redirect to="/" /> */}
      </>
    );
};

import { Capacitor } from "@capacitor/core";
import { Stripe } from "@capacitor-community/stripe";

const App: React.FC<any> = () => {
  // Hooks
  const { loading } = useFetchUser();
  const snackbarRef: any = createRef();

  // Initialize the Stripe plugin
  if (Capacitor.isPluginAvailable("Stripe")) {
    Stripe.initialize({
      publishableKey:
        "pk_live_51LPciHFmjrnOz7xM4Cc0VM5ZQSypxHTVmY4dN60Jt6FyWrjUNErwktyN4JAmCzDTaUpqAcDAP9XaGCkF3w19bFNA00wTZvqdfd", // MOVE THIS LATER ?????
    });
  }

  console.log(`%cVersion: ${versionObj && versionObj.version}`, "color: #bada55");
  // useVersion(); // ????? Temporary.  Should only be called on line 158

  const isMobile = isPlatform("mobile");

  if (isMobile) {
    document.documentElement.style.setProperty("--ion-background-color", "#000");
    // useVersion();
  } else {
    document.documentElement.style.setProperty("--ion-background-color", "#1f1f1f");
  }

  const toggleDarkTheme = () => {
    document.body.classList.add("dark");
  };

  useEffect(() => {
    toggleDarkTheme();
  }, []);

  if (settings.isMobile) {
    return (
      <IonApp>
        {loading ? (
          <Loading loading={true} />
        ) : (
          <IonReactRouter>
            <AppUrlListener />
            <MainLayout />
            <IonRouterOutlet id="main-content">
              <Route path="/profile" component={Profile} />
              <Route path="/events" component={Events} exact />
              <Route path="/event/:id" component={Event} exact />
              <Route path="/carbon" component={Carbon} exact />
              <Route path="/" component={Home} exact />
            </IonRouterOutlet>
          </IonReactRouter>
        )}
      </IonApp>
    );
  } else {
    return (
      <BrowserRouter>
        <SnackbarProvider
          className="snackbar__container"
          ref={snackbarRef}
          maxSnack={3}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          autoHideDuration={2000}
        >
          <Routing />
        </SnackbarProvider>
      </BrowserRouter>
    );
  }
};

export default App;
function useState(arg0: boolean): [any, any] {
  throw new Error("Function not implemented.");
}
