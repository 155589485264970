import { useEffect } from "react";

// Capacitor
import { App, URLOpenListenerEvent } from "@capacitor/app";

// Assets
import failedBackground from "./assets/icons/failed-background.svg";

// Hooks
import { useHistory } from "react-router";
import { useIonToast } from "@ionic/react";

// Firebase
import { isSignInWithEmailLink, signInWithEmailLink } from "firebase/auth";
import { auth } from "./firebase/firebase";

export const AppUrlListener = () => {
  // Hooks
  const history = useHistory();
  const [present] = useIonToast();

  console.warn("APP URL LISTENER OPENING");

  const onAppOpen = (event: URLOpenListenerEvent) => {
    if (isSignInWithEmailLink(auth, event.url)) {
      const emailForSignIn = window.localStorage.getItem("email");

      if (!emailForSignIn) {
        present({
          message:
            "Please click the magic link from the device it was requested",
          duration: 5000,
          position: "bottom",
          icon: failedBackground,
        });
        return;
      }

      signInWithEmailLink(auth, emailForSignIn, event.url)
        .then((result) => {
          window.localStorage.removeItem("email");
        })
        .catch((error) => {
          console.error(error);
          return;
          // Show snackbar of "An unknown error has occured"
        });
    }
  };

  useEffect(() => {
    App.addListener("appUrlOpen", onAppOpen);
  }, []);

  return <></>;
};
