import serviceConfig from "./firebaseConfig.json";
import settings from "./../settings.json";

import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import {
  initializeAuth,
  indexedDBLocalPersistence,
  getAuth,
  Auth,
} from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getFunctions } from "firebase/functions";

const app = initializeApp(serviceConfig);

let auth: Auth;
if (!settings.isMobile) {
  auth = getAuth();
} else {
  auth = initializeAuth(app, { persistence: indexedDBLocalPersistence });
}

export { auth };

export const firestore = getFirestore();
export const storage = getStorage();
export const functions = getFunctions();
