import React from "react";


const Loader: React.FC = () => (
    <div className="loader__container">
        {/* <LinearProgress color="primary" /> */}
    </div>
);

export default Loader;
