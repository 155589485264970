import React from "react";

// Assets
import loadingGif from "../../assets/gifs/loading.gif";

export const Loading: React.FC<{ loading: boolean }> = ({ loading }) => {
    if (loading)
        return (
            <div className="loading__container parent">
                <div className="child">
                    <img className="nofilterimg" src={loadingGif} alt="loading" />
                </div>
            </div>
        );
    else return <></>;
};

export default Loading;
